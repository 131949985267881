/*
  Authors : WeAiSys (Mozib Khan)
  Website : https://weaisys.com/
  App Name : Medical Plus
  Created : 9 May 2021
  This App Template Source code is licensed as per the
  terms found in the Website https://weaisys.com/license
  Copyright and Good Faith Purchasers © 2022-present WeAiSys Helps.
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}
