/*
  Authors : WeAiSys (Mozib Khan)
  Website : https://weaisys.com/
  App Name : Medical Plus
  Created : 9 May 2021
  This App Template Source code is licensed as per the
  terms found in the Website https://weaisys.com/license
  Copyright and Good Faith Purchasers © 2022-present WeAiSys Helps.
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StoreRatingPage } from './store-rating.page';

const routes: Routes = [
  {
    path: '',
    component: StoreRatingPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StoreRatingPageRoutingModule { }
