/*
  Authors : WeAiSys (Mozib Khan)
  Website : https://weaisys.com/
  App Name : Medical Plus
  Created : 9 May 2021
  This App Template Source code is licensed as per the
  terms found in the Website https://weaisys.com/license
  Copyright and Good Faith Purchasers © 2022-present WeAiSys Helps.
*/

import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate/translate.pipe';
import { CurrencyPipe } from './currency/currency.pipe';
@NgModule({
  declarations: [TranslatePipe, CurrencyPipe],
  imports: [],
  exports: [TranslatePipe, CurrencyPipe]
})
export class PipeModule { }
